.App {
  text-align: center;
}


.main {
  background: #25292D;
  color: #ced4da;
  min-height: 100vh;
  font-family: 'Playfair Display', serif;
}

.navbar {
  background-color: rgb(46, 51, 56);
}

.navbar .container {
  padding-left: 0;
  margin-left: 2rem;
  justify-content: flex-start;
  height: 3rem;
}

.navbar-collapse {
  margin-left: 3rem;
}
.navbar-light .navbar-nav .nav-link {
  color: #ADB5BD;
  text-decoration: none;
  font-size: large;
  margin-right: 10px;
}


.navbar-brand {
  font-family: 'Qwitcher Grypen', cursive;
  font-size: 2.5rem;
}

.navbar-light .navbar-brand {
  color: #caa734;
}

.projectContainer h1 {
  padding-bottom: 2px;
  border-bottom: solid #343a40 2px;
  color: #D5B038;
}
.techContainer h1 {
  padding-bottom: 2px;
  border-bottom: solid #343a40 2px;
  color: #D5B038;

}
.aboutcontainer {
  display: flex;
  flex-direction: row;
  padding: 5rem 2.5rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-height: 400px;
  width: 90vw;
}

.intro {
  flex-grow: 1;
  text-align: left;
  align-items: flex-start;
  width: 50%;  
  flex-direction: column;
}

.codingsvg {
  width: 50%;
}

.mssgsvg {
  width: 50%;
  display: flex;
  height: 40rem
}

.fa-code {
  font-size: 2rem;
  color: #CD9900;
}

.btnGrp {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 90%;
  align-self: center;
  justify-content: center;
}

.btn {
  background-color: #343a40;
  display: inline-block;
  color: #CD9900;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  border-color: #CD9900 ;
  border-radius: 8px;
  width: 30%;
  text-align: center;
  padding: .5rem 1.5rem;
  font-weight: 500;
  font-size: x-large;
  
}

.techContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-width: 90%;
}

.techIcons {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}
h4 {
  font-size: 1.25rem;
}

.singleIcons {
  height: 60px;
  width: 60px;
  margin: 2rem 2rem 1rem;
}

.form-control {
  background-color: #343a40;
  padding: .5rem;
  border: #495057 solid 2px;
  border-radius: 8px;
  color: #ADB5BD;
}

form {
  padding: 2rem;
  border: #495057 solid 2px;
  border-radius: 8px;
  background-color: #495057;
}

.formGroup {
  margin-bottom: 1rem;
}

.projectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  flex-grow: 1;
  width: 90vw;
  height: max-content;
}

.project {
  height: 100%;
  width: 100%;
}
.projectIcon {
  height: 20rem;
  margin-top: 2.5rem;
}


h1 {
  font-size: 2rem;
}

.intro > h1 {
  font-size: 2.5rem;
  display: inline;
}

.intro > p {
  font-size: 1.15rem;
  margin-top: 1.25rem;
  margin-bottom: 2rem;
}

.projectIcon > h5 {
  margin-top: .5rem;
  font-size: 1.25rem;
  color: #ADB5BD;
}

a {
  text-decoration: none;
}

.form-label {
  font-size: large;
  color: rgb(213, 176, 56);
}

.formMssg {
  width: 75%;
}

.formGroup > .form-control {
  font-family: 'Playfair Display', serif;
}

.formGroup > .form-text {
  opacity: 1;
  
}

.footerContainer {
  margin-bottom: 0;
  height: 5rem;
  margin-top: 2rem;
  text-align: center;
}

.footerIcons {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #D5B038;}

.navbar-light .navbar-nav .nav-link:hover {
  transform: scale(1.25);
  color: #D5B038;
}
.navbar-light .navbar-brand:focus {
  color: #caa734;
}
.navbar-light .navbar-brand:hover {
  color: #caa734;

}
.btn:hover {
  background-image: linear-gradient(to right, #B88746, #FDF5A6);
  border-color:  #B88746;
  color: #1C1F21;
  font-weight: 600;
}
.btn-primary:hover {
  background-image: linear-gradient(to right, #B88746, #FDF5A6);
  background-color: transparent;
}
.projectIcon:hover {
  transform: scale(1.2);
}
.projectIcon:hover > h5 {
  color: #caa734;
}
.form-control:focus {
  background-color: #343a40;
  color: #ADB5BD;
}

@media (min-width: 992px) {
  .navbar{
    height: 5rem;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 1.30rem;
  }
  .aboutcontainer {
    padding-bottom: 0;
  }
  .btnGrp {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-self: center;
    justify-content: space-evenly;
    width: 100%;
  }
  .btn {
    width: 40%;
  }
  .aboutcontainer {
    max-height: max-content;
    padding-top: 0;
  }
  .formCont {
    width: 80%;
  }
  .form-control {
    width: 60%;
  }
  .submitBtn {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .codingsvg {
    width: 90%;
  }
  .aboutcontainer {
    flex-direction: column-reverse;
    max-height: max-content;
    padding-top: 40px;
    width: 100%;
  }
  .intro {
    width: 90%;
  }
  .btnGrp {
    width: 100%;
    justify-content: space-evenly;
  }
  .btn {
    width: 45%;
 }
 .projectContainer {
   text-align: center;
   width: 100%;
 }
 .project {
   max-block-size: 90%;
 }
 .projectIcon {
   height: auto;
   width: auto;
 }
 .singleIcons {
   height: 45px;
   width: 45px;
 }
 .mssgsvg {
  width: 80%;
  height: auto;
}
 .formMssg {
   width: 100%;
 }
 .submitBtn {
   width: 100%;
 }
 .formElem {
   width: 100%;
 }
 .formCont {
  flex-direction: column;
  margin: auto;
  padding-top: 0;
  border-top: #343a40 dotted 2px;
  border-radius: 10%;
 }
}

@media (max-width: 767px) {
  .navbar-light .navbar-nav .nav-link:hover {
    transform: scale(1);
  }
  .navbar .container {
    justify-content: space-between;
    margin-left: .5rem;
  }
  .navbar-collapse {
    text-align: right;
  }
  .aboutBox {
    padding-top: 2rem;
  }
  .codingsvg {
    width: 90%;
  }
  .aboutcontainer {
    flex-direction: column-reverse;
    max-height: max-content;
    padding-top: 40px;
    width: 100%;
  }
  .intro {
    width: 90%;
  }
  .btnGrp {
    width: 100%;
    justify-content: space-between;
  }
  .btn {
    width: 45%;
    font-size: large;
 }
 .projectContainer {
   text-align: center;
   width: 100%;
 }
 .project {
   max-block-size: 90%;
 }
 .projectIcon {
   height: auto;
   width: auto;
 }
 .singleIcons {
   height: 45px;
   width: 45px;
 }
 .mssgsvg {
   display: none;
 }
 .formMssg {
   width: 100%;
 }
 .submitBtn {
   width: 100%;
 }
 .formElem {
   width: 100%;
 }
}

